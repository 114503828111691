<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">

          <GridMenu class="my-8" />

          <v-stepper v-model="step" vertical elevation="0">
            <v-stepper-step
              step="1"
              class="cursor-pointer"
              :complete="hasAddedWishes"
              @click.prevent="step = 1"
              >Lägg till önskningar</v-stepper-step
            >
            <v-stepper-content step="1"
              ><p>
                Börja med att
                <router-link to="/newwish">lägga till önskningar</router-link> i
                din önskelista. Om du är osäker på vad du kommer önska dig går
                det såklart att dela din lista trots att den är tom. Dina vänner
                kommer att se önskningar vartefter de dyker upp i listan.
              </p></v-stepper-content
            >

            <v-stepper-step
              step="2"
              class="cursor-pointer"
              :complete="hasSharedWishList"
              @click.prevent="step = 2"
              >Dela din önskelista</v-stepper-step
            >
            <v-stepper-content step="2">
              <p class="">
                Du kan
                <router-link :to="`/accounts/${accountId}/share`"
                  >dela din önskelista</router-link
                >
                på olika sätt. Om du vill dela din lista till någon som är på
                samma plats kan du enkelt låta dem skanna din kod, annars
                skickar du en länk via mail, sms eller annat valfritt sätt.
              </p>
            </v-stepper-content>

            <v-stepper-step
              step="3"
              class="cursor-pointer"
              :complete="hasAddedFriends"
              @click.prevent="step = 3"
              >Lägg till vänner</v-stepper-step
            >
            <v-stepper-content step="3"
              >Precis som andra lade till din önskelista kan du börja lägga till
              andras. Be helt enkelt att få
              <router-link to="/addfriendaccount">skanna en kod</router-link>
              eller få en länk till deras önskelista.</v-stepper-content
            >
            <!-- PLEASE DO NOT FORGET to modify calculateStep when adding more steps here -->
          </v-stepper>
        </v-col>
      </v-row>

      <v-row v-if="deferredPrompt">
        <v-col>
          <div class="d-flex align-center">
            <v-btn color="primary" fab small @click="install">
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <div class="ml-4">
              Få ut mer av Önskeappen genom att installera den i din telefon.
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import client from "@/utils/ApiClient";
import Info from "../components/Info";
import GridMenu from "../components/GridMenu";

export default {
  name: "Home",
  data: () => ({
    step: 0,
    deferredPrompt: null,
  }),
  components: {
    GridMenu,
    Info,
  },
  computed: {
    account() {
      return this.$store.state.accounts.accounts[parseInt(this.accountId)];
    },
    accountId() {
      return this.$store.state.accounts.primaryAccount;
    },
    hasAddedWishes() {
      return this.$store.getters.wishesBelongingTo(this.accountId).length > 0;
    },
    hasSharedWishList() {
      return this.$store.state.friendships.sharedToFriendIds.length > 0;
    },
    hasAddedFriends() {
      return this.$store.state.friendships.friendshipIds.length > 0;
    },
  },
  methods: {
    calculateStep() {
      this.step =
        [
          this.hasAddedWishes,
          this.hasSharedWishList,
          this.hasAddedFriends,
        ].indexOf(false) + 1;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  async mounted() {
    await this.$store.dispatch("getSharedToFriends", this.accountId);
    this.calculateStep();
  },
};
</script>
